export const generateTOC = () => {
    const tocList = document.querySelector("[vdx-toc-list]");
    const tocArticle = document.querySelector("[vdx-toc-article]");

    if (!tocList || !tocArticle) return;

    const headings = tocArticle.querySelectorAll("h1, h2, h3, h4, h5, h6");
    if (headings.length === 0) return;

    // Wrap each header and its following content in a div with the id (no longer assigning id to headers)
    headings.forEach((heading, index) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.id = "header-" + index; // Set id only on the wrapperDiv

        // Insert the wrapperDiv before the heading
        heading.parentNode.insertBefore(wrapperDiv, heading);

        // Move the heading inside the wrapperDiv
        wrapperDiv.appendChild(heading);

        // Move the following siblings into the wrapperDiv until the next heading or end
        let nextSibling = wrapperDiv.nextElementSibling;
        while (nextSibling && !nextSibling.tagName.match(/^H[1-6]$/i)) {
            const sibling = nextSibling;
            nextSibling = nextSibling.nextElementSibling;
            wrapperDiv.appendChild(sibling);
        }
    });

    // Continue with the existing TOC generation logic
    ["h1", "h2", "h3", "h4", "h5", "h6"].forEach(tag => {
        let tocHeading = tocList.querySelector(`[vdx-toc-${tag}]`);
        if (tocHeading) {
            tocHeading.style.display = "none";
        }
    });

    headings.forEach((heading, index) => {
        const tagName = heading.tagName.toLowerCase();
        const tocHeading = tocList.querySelector(`[vdx-toc-${tagName}]`);
        if (tocHeading) {
            const listItem = document.createElement("li");
            if (tocHeading.className) listItem.className = tocHeading.className;

            const anchor = document.createElement("a");
            anchor.href = `#header-${index}`;  // Now points to the wrapping div's id
            anchor.textContent = heading.textContent;

            const tocAnchor = tocHeading.querySelector("a");
            if (tocAnchor && tocAnchor.className) {
                anchor.className = tocAnchor.className;
            }

            const computedStyle = window.getComputedStyle(listItem);
            anchor.style.fontSize = computedStyle.fontSize;
            anchor.style.fontWeight = computedStyle.fontWeight;
            anchor.style.fontFamily = computedStyle.fontFamily;
            anchor.style.lineHeight = computedStyle.lineHeight;
            anchor.style.color = computedStyle.color;
            anchor.style.textDecoration = computedStyle.textDecoration;

            listItem.appendChild(anchor);
            tocList.appendChild(listItem);
        }
    });
};
