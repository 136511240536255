export const calculateReadTime = (text) => Math.ceil(text.split(/\s+/).length / 200);

export const countUpAnimation = (element, target, interval) => {
    let count = 0;
    element.textContent = "0";
    let timer = setInterval(() => {
        count++;
        element.textContent = `${count}`;
        if (count >= target) clearInterval(timer);
    }, interval);
};

export const displayError = (element, message) => {
    console.error(`Error: ${message}`);
    element.setAttribute("title", message);
    element.style.border = "1px dotted red";
    element.style.cursor = "help";
};

export const processReadTimeLink = async (element) => {
    let url = element.getAttribute("href");
    let articleId = element.getAttribute("vdx-readtime-link");
    let cacheKey = `${url}-${articleId}`;
    let cachedTime = localStorage.getItem(cacheKey);
    let interval = parseInt(element.getAttribute("vdx-readtime-interval")) || 200;

    const findTargetElement = (el) => el.querySelector("[vdx-readtime-time]") || el.closest(".w-dyn-item")?.querySelector("[vdx-readtime-time]");
    const isValidTarget = (el, target) => {
        let parent = el.closest(".w-dyn-item");
        return parent ? parent.contains(target) : true;
    };

    if (cachedTime) {
        let targetElement = findTargetElement(element);
        if (targetElement && isValidTarget(element, targetElement)) {
            element.removeAttribute("title");
            element.style.border = "";
            if (element.getAttribute("vdx-animation") === "countup") {
                countUpAnimation(targetElement, parseInt(cachedTime), interval);
            } else {
                targetElement.textContent = `${cachedTime}`;
            }
        } else {
            displayError(element, "No target element found or mismatch within .w-dyn-item.");
        }
        return;
    }

    try {
        let response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
        let html = await response.text();
        let doc = new DOMParser().parseFromString(html, "text/html");
        let articles = doc.querySelectorAll(`[vdx-readtime-article="${articleId}"]`);
        
        if (articles.length > 0) {
            let content = Array.from(articles).map(el => el.textContent.trim()).join(" ");
            if (content) {
                let readTime = calculateReadTime(content);
                let targetElement = findTargetElement(element);
                if (targetElement && isValidTarget(element, targetElement)) {
                    element.removeAttribute("title");
                    element.style.border = "";
                    if (element.getAttribute("vdx-animation") === "countup") {
                        countUpAnimation(targetElement, readTime, interval);
                    } else {
                        targetElement.textContent = `${readTime}`;
                    }
                    localStorage.setItem(cacheKey, readTime);
                } else {
                    displayError(element, "No target element found or mismatch within .w-dyn-item.");
                }
            } else {
                displayError(element, `No text content found in elements with vdx-readtime-article="${articleId}" on ${url}.`);
            }
        } else {
            displayError(element, `No elements with vdx-readtime-article="${articleId}" found on ${url}.`);
        }
    } catch (error) {
        displayError(element, `Error fetching content from ${url}: ${error.message}`);
    }
};

export const handleReadTimeOnPageLoad = () => {
    let articlesSet = new Set(Array.from(document.querySelectorAll("[vdx-readtime-article]")).map(el => el.getAttribute("vdx-readtime-article")));
    let timesSet = new Set(Array.from(document.querySelectorAll("[vdx-readtime-time]")).map(el => el.getAttribute("vdx-readtime-time")));

    articlesSet.forEach(articleId => {
        let articleElements = document.querySelectorAll(`[vdx-readtime-article="${articleId}"]`);
        let timeElements = Array.from(document.querySelectorAll(`[vdx-readtime-time="${articleId}"]`)).filter(el => !el.closest(".w-dyn-item"));

        if (timeElements.length > 0) {
            if (articleElements.length === 0) {
                timeElements.forEach(el => displayError(el, `No elements with vdx-readtime-article="${articleId}" found.`));
            } else {
                let content = Array.from(articleElements).map(el => el.textContent.trim()).join(" ");
                if (content) {
                    let readTime = calculateReadTime(content);
                    timeElements.forEach(el => {
                        let interval = parseInt(el.getAttribute("vdx-readtime-interval")) || 200;
                        if (el.getAttribute("vdx-animation") === "countup") {
                            countUpAnimation(el, readTime, interval);
                        } else {
                            el.textContent = `${readTime}`;
                        }
                    });
                } else {
                    articleElements.forEach(el => displayError(el, `No text content found in elements with vdx-readtime-article="${articleId}".`));
                }
            }
        }
    });

    timesSet.forEach(timeId => {
        if (!articlesSet.has(timeId)) {
            Array.from(document.querySelectorAll(`[vdx-readtime-time="${timeId}"]`)).filter(el => !el.closest(".w-dyn-item")).forEach(el => {
                displayError(el, `No elements with vdx-readtime-article="${timeId}" found.`);
            });
        }
    });
};
