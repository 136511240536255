export const socialShare = () => {
    const getCurrentPageDetails = () => ({
        url: window.location.href,
        title: document.title,
    });

    // Initialize the alert element to be hidden on load
    const alertElement = document.querySelector("[vdx-share-alert]");
    if (alertElement) {
        alertElement.style.display = "none";
        alertElement.style.opacity = "0";
    }

    document.querySelector("[vdx-share-facebook]")?.addEventListener("click", () => {
        const { url } = getCurrentPageDetails();
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        window.open(shareUrl, "_blank");
    });

    document.querySelector("[vdx-share-twitter]")?.addEventListener("click", () => {
        const { url, title } = getCurrentPageDetails();
        const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
        window.open(shareUrl, "_blank");
    });

    document.querySelector("[vdx-share-linkedin]")?.addEventListener("click", () => {
        const { url, title } = getCurrentPageDetails();
        const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
        window.open(shareUrl, "_blank");
    });

    document.querySelector("[vdx-share-page]")?.addEventListener("click", () => {
        const { url } = getCurrentPageDetails();
        navigator.clipboard.writeText(url)
            .then(() => {
                if (alertElement) {
                    alertElement.style.display = "block";
                    alertElement.style.opacity = "1";
                    setTimeout(() => {
                        alertElement.style.opacity = "0";
                        setTimeout(() => { alertElement.style.display = "none"; }, 1000);
                    }, 2000);
                } else {
                    alert("Page URL copied to clipboard: " + url);
                }
            })
            .catch(err => console.error("Error copying URL:", err));
    });

    document.querySelector("[vdx-share-email]")?.addEventListener("click", () => {
        const { url, title } = getCurrentPageDetails();
        const subject = `Check out this page: ${title}`;
        const body = `I found this interesting and wanted to share it with you: ${url}`;
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    });
};
