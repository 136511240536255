export const timedTabs = () => {
    document.querySelectorAll("[vdx-tabs-timed]").forEach((tabGroup) => {
        const tabs = Array.from(tabGroup.querySelectorAll(".w-tab-link"));
        const tabPanes = Array.from(tabGroup.querySelectorAll(".w-tab-pane"));
        let currentIndex = 0;
        let intervalId, hoverTimeoutId;

        const switchInterval = parseInt(tabGroup.getAttribute("vdx-tabs-timed")) || 3000;
        const pauseDelay = parseInt(tabGroup.getAttribute("vdx-tabs-pause")) || 0;
        const hasPauseAttr = tabGroup.hasAttribute("vdx-tabs-pause");

        // Switch tabs
        const switchTab = () => {
            tabs.forEach((tab, index) => {
                const isActive = index === currentIndex;
                tab.classList.toggle("w--current", isActive);
                tab.setAttribute("aria-selected", isActive);
                if (tabPanes.length) tabPanes[index].classList.toggle("w--tab-active", isActive);
            });
            currentIndex = (currentIndex + 1) % tabs.length;
        };

        // Start rotation with optional delay
        const startRotation = (delay = 0) => {
            clearInterval(intervalId);
            clearTimeout(hoverTimeoutId);
            hoverTimeoutId = setTimeout(() => {
                intervalId = setInterval(switchTab, switchInterval);
            }, delay);
        };

        // Stop rotation
        const stopRotation = () => {
            clearInterval(intervalId);
            clearTimeout(hoverTimeoutId);
        };

        // Event listeners
        tabGroup.addEventListener("mouseenter", stopRotation);
        tabGroup.addEventListener("mouseleave", () => hasPauseAttr && startRotation(pauseDelay));
        tabs.forEach((tab, index) => {
            tab.addEventListener("click", () => {
                currentIndex = index;
                switchTab();
                stopRotation();
                if (hasPauseAttr) startRotation(pauseDelay);
            });
        });

        startRotation();
    });
};
