import { handleReadTimeOnPageLoad, processReadTimeLink } from './readtime.js';
import { socialShare } from './socialshare.js';
import { formatDate } from './date.js';
import { currentTime } from './time.js';
import { onClick } from './onclick.js';
import { copyToClipboard } from './copyclip.js';
import { popupsIntent } from './popup.js';
import { cmsNest } from './cmsnest.js';
import { backButtons } from './backbutton.js';
import { generateTOC } from './toc.js';
import { transitionDelay } from './transitiondelay.js';
import { showNavScroll } from './shownavscroll.js';
import { initializeCountdowns } from './countdowntimer.js';
import { initializeToastButtons } from './toast.js';
import { countUp } from './countup.js';
import { customTabs, timedTabs } from './tabs.js';
import { handleChildHoverState } from './hoverstate.js';
import { generateTooltip } from './tooltip.js';
import { cmsRandomSort } from './cmsrandomsort.js';
import { initSlider } from './slider.js';
import { filterCards } from './filters.js';

export const functions = () => {
    const nestLinks = document.querySelectorAll("[vdx-nest-link]");
    cmsRandomSort();
    if (nestLinks.length > 0) {
        cmsNest();
    }
    // Once cmsNest is done (or if no vdx-nest-link elements), run the other scripts
    document.querySelectorAll("[vdx-readtime-link]").forEach(processReadTimeLink);
    handleReadTimeOnPageLoad();
    socialShare();
    formatDate();
    currentTime();
    onClick();
    copyToClipboard();
    popupsIntent();
    backButtons();
    generateTOC();
    transitionDelay();
    showNavScroll();
    initializeCountdowns();
    initializeToastButtons();
    countUp();
    customTabs();
    timedTabs();
    handleChildHoverState();
    generateTooltip();
    initSlider();
   // filterCards();
};