export function initSlider() {
    const sliders = document.querySelectorAll("[vdx-slider-slider]");
    console.log('sliders', sliders);
    sliders.forEach(slider => {
        const autoRotate = slider.hasAttribute('vdx-slider-autorotate') ? slider : null;
        console.log('autoRotate', autoRotate);
        if (slider.dataset.initialized) return; // Prevent re-initialization
        slider.dataset.initialized = true; // Mark as initialized
        const slides = slider.querySelectorAll('[vdx-slider-slide]');
        if (slides.length === 0) {
            console.log('No slides found');
            return;
        }
       
        const prevButton = slider.querySelector('[vdx-slider-prev]');
        if (!prevButton) {
            console.log('No prev button found');
            return;
        }
       
        const nextButton = slider.querySelector('[vdx-slider-next]');
        if (!nextButton) {
            console.log('No next button found');
            return;
        }
        
        const autoRotateTime = slider.getAttribute('vdx-slider-autorotate') || 3000; // Default auto rotate time is 3000ms
        
        prevButton.classList.add('vdx--inactive');
        if (slides.length === 1) {
            nextButton.setAttribute('aria-disabled', 'true');
            nextButton.classList.add('vdx--inactive');
        }
        nextButton.setAttribute('aria-disabled', 'false');

        let initialSlideIndex = 0;
        slides[initialSlideIndex].classList.add('vdx--active');
    
        
        // Add vdx--active class to all children of the initial active slide
        slides[initialSlideIndex].querySelectorAll('*').forEach(child => {
            child.classList.add('vdx--active'); // Add to children
        });
        const slideWidth = slides[0] ? slides[0].offsetWidth + parseInt(getComputedStyle(slides[0]).marginLeft) + parseInt(getComputedStyle(slides[0]).marginRight) : 0;
        console.log('slideWidth', slideWidth);
        
        const slidesCount = slides.length;

        function moveSlider(index) {
            slides.forEach((slide, i) => {
                slide.style.transform = `translateX(${(- index) * slideWidth}px)`;
                slide.classList.remove('vdx--active');
                slide.querySelectorAll('*').forEach(child => {
                    child.classList.remove('vdx--active'); // Remove from children
                });
                if (i === index) {
                    slide.classList.add('vdx--active');
                    slide.querySelectorAll('*').forEach(child => {
                        child.classList.add('vdx--active'); // Add to children
                    });
                }
            });
            initialSlideIndex = index;
            prevButton.classList[index === 0 ? 'add' : 'remove']('vdx--inactive');
            prevButton.setAttribute('aria-disabled', index === 0 ? 'true' : 'false');
            nextButton.setAttribute('aria-disabled', index === slidesCount - 1 ? 'true' : 'false');
            nextButton.classList[index === slidesCount - 1 ? 'add' : 'remove']('vdx--inactive');
            slider.querySelectorAll('[vdx-slider-dot]').forEach((dot, i) => {
                dot.classList.remove('vdx--active');
                if (i === index) dot.classList.add('vdx--active');
            });
        }

        function handleButtonClick(event) {
            const button = event.currentTarget;
            moveSlider(button === prevButton ? (initialSlideIndex - 1 + slidesCount) % slidesCount : (initialSlideIndex + 1) % slidesCount);
        }

        
        prevButton.addEventListener('click', (event) => {
            if (prevButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });
        
        nextButton.addEventListener('click', (event) => {
            if (nextButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });

        let dotTemplate = slider.querySelector('[vdx-slider-dot]');
        let navigationContainer = slider.querySelector('[vdx-slider-nav]');
        if (!navigationContainer) return;
        navigationContainer.innerHTML = '';
        slides.forEach((slide, index) => {
            const dot = dotTemplate.cloneNode(true);
            dot.setAttribute('data-slide-index', index);
            navigationContainer.appendChild(dot);
            dot.addEventListener('click', () => moveSlider(index));
        });
        let initialDot = navigationContainer.querySelector(`[data-slide-index="0"]`);
        initialDot.classList.add('vdx--active');

        // Only autorotate if vdx-slider-autorotate exists in a slide
        if (autoRotate) {
            console.log('autoRotate', autoRotate);
            let autoRotateInterval = setInterval(() => moveSlider((initialSlideIndex + 1) % slidesCount), autoRotateTime);
            slider.addEventListener('mouseover', () => clearInterval(autoRotateInterval));
            slider.addEventListener('mouseout', () => autoRotateInterval = setInterval(() => moveSlider((initialSlideIndex + 1) % slidesCount), autoRotateTime));
        }
    });
}
