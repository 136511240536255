import { functions } from './functions.js';

export const cmsNest = async () => {
    const displayFetchError = (element, error) => {
        console.error("Error executing vdx-nest-link code:", error);
        if (element) {
            element.style.border = "1px red dotted"; // Apply red border to highlight the error
            element.setAttribute("title", error.message); // Show error message in title attribute
            element.style.cursor = "help"; // Change cursor to help style
        } else {
            console.warn("No valid element provided for displaying fetch error");
        }
    };

    const fetchAndReplaceContent = async (element) => {
        const url = element.getAttribute("href");
        const selector = element.getAttribute("vdx-nest-link");

        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch content from ${url}: ${response.statusText}`);

            const content = await response.text();
            const doc = new DOMParser().parseFromString(content, "text/html");

            // Get all matching elements with vdx-nest-this attribute in fetched content
            const selectedElements = selector ? doc.querySelectorAll(`[vdx-nest-this="${selector}"]`) : [];

            // If no matching elements found, trigger error
            if (selectedElements.length === 0) {
                throw new Error(`No matching elements found for selector [vdx-nest-this="${selector}"]`);
            }

            // Create a document fragment to hold the new elements
            const fragment = document.createDocumentFragment();

            // Append all matching elements to the fragment
            selectedElements.forEach((selectedElement) => {
                fragment.appendChild(selectedElement.cloneNode(true)); // Clone the element before appending
            });

            // Replace the vdx-nest-link element with all selected elements
            if (element.parentNode) {
                element.parentNode.replaceChild(fragment, element);
            }

            // Execute all the necessary functions after content replacement
            await executePostFetchFunctions();

            return fragment;

        } catch (error) {
            // Error handling if no matching elements found or fetch fails
            displayFetchError(element, error);
        }
    };

    const executePostFetchFunctions = async () => {
        functions();
    };

    const processNestedElements = async (parentFragment) => {
        // Check if any of the newly inserted elements have vdx-nest-link
        const nestedNestLinks = parentFragment.querySelectorAll("[vdx-nest-link]");

        if (nestedNestLinks.length > 0) {
            // Wait for all nested vdx-nest-link elements to be processed
            await Promise.all(Array.from(nestedNestLinks).map(link => fetchAndReplaceContent(link).then(processNestedElements)));
        }
    };

    const reinitializeWebflow = async () => {
        let cmsNestLinks = document.querySelectorAll("[vdx-nest-link]");

        while (cmsNestLinks.length > 0) {
            // Fetch and replace content for all top-level vdx-nest-link elements
            await Promise.all(Array.from(cmsNestLinks).map(link => fetchAndReplaceContent(link).then(processNestedElements)));

            // Find any new vdx-nest-link elements added after the previous batch
            cmsNestLinks = document.querySelectorAll("[vdx-nest-link]");
        }

        // Reinitialize Webflow after all nested elements are processed
        if (typeof Webflow !== "undefined") {
            Webflow.destroy();
            Webflow.ready();
            Webflow.require("ix2").init();
        } else {
            console.warn("Webflow is not defined.");
        }
    };

    // Return a promise that resolves once reinitializeWebflow is complete
    return reinitializeWebflow();
};