import { addNthChildClass } from './nthchild.js';

export const cmsRandomSort = () => {
    const shuffleElements = document.querySelectorAll('[vdx-sort-random]');
    shuffleElements.forEach(parent => {
        const maxChildren = parseInt(parent.getAttribute('vdx-sort-random') || 999);
        const children = Array.from(parent.children);
        children.sort(() => Math.random() - 0.5);
        if (maxChildren) {
            children.slice(maxChildren).forEach(child => parent.removeChild(child));
            children.slice(0, maxChildren).forEach(child => parent.appendChild(child));
        } else {
            children.forEach(child => parent.appendChild(child));
        }
    });

    setTimeout(() => {
        addNthChildClass();
    }, 0);
}