export const popupsIntent = () => {
    const convertHoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;

    const getLastShownTime = (key) => parseInt(localStorage.getItem(key)) || 0;

    const updateLastShownTime = (key) => localStorage.setItem(key, Date.now().toString());

    const shouldShowPopup = (key, frequency) => Date.now() - getLastShownTime(key) >= convertHoursToMilliseconds(frequency);

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
        document.body.style.overflow = "";
    };

    const hidePopup = (popup, key) => {
        popup.style.opacity = "0";
        setTimeout(() => {
            popup.style.display = "none";
            enableScroll();
            updateLastShownTime(key);
        }, 500);
    };

    const showPopup = (popup, key) => {
        popup.style.display = "block";
        disableScroll();
        setTimeout(() => {
            popup.style.opacity = "1";
    
            // Find the first focusable element in the popup
            const focusableElements = popup.querySelectorAll(
                'a, [role=button], button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
              );
            const firstFocusable = focusableElements[0];
            
            if (firstFocusable) {
                firstFocusable.focus(); // Move focus to the first focusable element
            }
        }, 10);
        updateLastShownTime(key);
    };
    

    let popupOnScrollDownElement, popupOnExitElement, popupOnScrollUpElement;

    const handleScrollDownPopup = () => {
        popupOnScrollDownElement = document.querySelector("[vdx-popup-scrolldown]");
        if (popupOnScrollDownElement) {
            const scrollThreshold = parseInt(popupOnScrollDownElement.getAttribute("vdx-popup-scrolldown")) || 50;
            const frequency = parseInt(popupOnScrollDownElement.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnScrollDownShown";

            if (shouldShowPopup(storageKey, frequency)) {
                const scrollListener = () => {
                    const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
                    if (scrollPercent >= scrollThreshold) {
                        showPopup(popupOnScrollDownElement, storageKey);
                        window.removeEventListener("scroll", scrollListener);
                    }
                };
                popupOnScrollDownElement.style.opacity = "0";
                popupOnScrollDownElement.style.display = "none";
                window.addEventListener("scroll", scrollListener);
                popupOnScrollDownElement.addEventListener("click", () => hidePopup(popupOnScrollDownElement, storageKey));
            }
        }
    };

    const handleExitPopup = () => {
        popupOnExitElement = document.querySelector("[vdx-popup-onexit]");
        if (popupOnExitElement) {
            const frequency = parseInt(popupOnExitElement.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnExitShown";

            if (shouldShowPopup(storageKey, frequency)) {
                const exitListener = (event) => {
                    if (event.clientY <= 0) {
                        showPopup(popupOnExitElement, storageKey);
                        document.removeEventListener("mouseleave", exitListener);
                    }
                };
                popupOnExitElement.style.opacity = "0";
                popupOnExitElement.style.display = "none";
                document.addEventListener("mouseleave", exitListener);
                popupOnExitElement.addEventListener("click", () => hidePopup(popupOnExitElement, storageKey));
            }
        }
    };

    const handleScrollUpPopup = () => {
        popupOnScrollUpElement = document.querySelector("[vdx-popup-scrollup]");
        if (popupOnScrollUpElement) {
            const scrollThreshold = parseInt(popupOnScrollUpElement.getAttribute("vdx-popup-scrollup")) || 10;
            const frequency = parseInt(popupOnScrollUpElement.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnScrollUpShown";
            let lastScrollY = window.scrollY;

            if (shouldShowPopup(storageKey, frequency)) {
                const scrollListener = () => {
                    const currentScrollY = window.scrollY;
                    if (lastScrollY > currentScrollY && (lastScrollY - currentScrollY) / window.innerHeight * 100 >= scrollThreshold) {
                        showPopup(popupOnScrollUpElement, storageKey);
                        window.removeEventListener("scroll", scrollListener);
                    }
                    lastScrollY = currentScrollY;
                };
                popupOnScrollUpElement.style.opacity = "0";
                popupOnScrollUpElement.style.display = "none";
                window.addEventListener("scroll", scrollListener);
                popupOnScrollUpElement.addEventListener("click", () => hidePopup(popupOnScrollUpElement, storageKey));
            }
        }
    };

    handleScrollDownPopup();
    handleExitPopup();
    handleScrollUpPopup();

    // Close all popups if the Escape key is pressed
    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape") {
            if (popupOnScrollDownElement && popupOnScrollDownElement.style.display === 'block') {
                hidePopup(popupOnScrollDownElement, 'popupOnScrollDownShown');
            }
            if (popupOnExitElement && popupOnExitElement.style.display === 'block') {
                hidePopup(popupOnExitElement, 'popupOnExitShown');
            }
            if (popupOnScrollUpElement && popupOnScrollUpElement.style.display === 'block') {
                hidePopup(popupOnScrollUpElement, 'popupOnScrollUpShown');
            }
        }
    });
};
